import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, switchMap, tap } from 'rxjs';

import { environment } from '@data-stand/environments';
import { User } from '@shared/interfaces';
import { UserProfileStore } from '@store/user-profile.store';

import { RouterService } from '../router.service';
import { SnackBarService } from '../snackbar.service';

interface SignUpApiRequest {
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  re_password: string;
}

interface SignUpApiResponse {
  username: string;
  email: string;
}

interface LoginApiResponse {
  auth_token: string;
}

interface ResendActivationResponse {
  email: string;
}

@Injectable({ providedIn: 'root' })
export class AuthService {
  readonly #http = inject(HttpClient);
  readonly #router = inject(RouterService);
  readonly #snackBar = inject(SnackBarService);
  readonly #userStore = inject(UserProfileStore);

  url = environment.backendApiUrl;
  authToken?: string;

  get token() {
    return localStorage.getItem('Token');
  }

  get user() {
    if (!this.#userStore.currentUser()) return null;
    return this.#userStore.currentUser();
  }

  get username() {
    return this.#userStore.currentUser()?.username;
  }

  get first_name() {
    return this.#userStore.currentUser()?.first_name;
  }

  get last_name() {
    return this.#userStore.currentUser()?.last_name;
  }

  get image() {
    return this.#userStore.currentUser()?.image;
  }

  activateUserAccount(uid: string, token: string): Observable<unknown> {
    return this.#http.post<unknown>(`${this.url}/users/activation/`, {
      uid,
      token
    });
  }

  clearStorage(): void {
    localStorage.removeItem('Token');
    localStorage.removeItem('User');
  }

  getApiUserMe(token: string): Observable<User> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + token
    });
    return this.#http.get<User>(`${this.url}/users/me/`, { headers });
  }

  login(username: string, password: string) {
    this.clearStorage();
    return this.#http
      .post<LoginApiResponse>(`${this.url}/auth/token/login/`, {
        username,
        password
      })
      .pipe(
        switchMap((res) => {
          this.setToken(res.auth_token);
          return this.getApiUserMe(res.auth_token);
        }),
        tap(() => {
          this.#router.navigateToHome();
        })
      );
  }

  logout() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + this.token
    });
    return this.#http
      .post<null>(`${this.url}/auth/token/logout/`, null, { headers })
      .subscribe({
        next: () => {
          this.clearStorage();
          // We always signout to the landing page.
          this.#router.navigateToLandingPage();
        },
        error: () => this.#snackBar.error('Failed to logout'),
        complete: () => this.#snackBar.success('Logged out succesfully')
      });
  }

  isLoggedIn() {
    return !!this.token && !!this.user;
  }

  resendActivation(email: string): Observable<ResendActivationResponse> {
    return this.#http.post<ResendActivationResponse>(
      `${this.url}/users/resend_activation/`,
      { email }
    );
  }

  // This always return HTTP 204 with no data, even if the email is not found.
  // This is by design to avoid leaking user information.
  resetPassword(email: string): Observable<void> {
    return this.#http.post<void>(`${this.url}/users/reset_password/`, {
      email
    });
  }

  resetPasswordConfirm(
    uid: string,
    token: string,
    password: string
  ): Observable<void> {
    return this.#http.post<void>(`${this.url}/users/reset_password_confirm/`, {
      uid,
      token,
      new_password: password
    });
  }

  setToken(token: string): void {
    if (localStorage.getItem('Token') !== token) {
      localStorage.setItem('Token', token);
    }
  }

  signUp(userAuth: SignUpApiRequest): Observable<SignUpApiResponse> {
    return this.#http.post<SignUpApiResponse>(`${this.url}/users/`, userAuth);
  }
}
